
import './styles/style.css'
import './plugins/bootstrap/css/bootstrap.css'
import './plugins/fontawesome/css/font-awesome.min.css'

import {
  NavBar, About,
  BannerIntro,
  ContactUs, Footer,
  Products, Services, Dot, Service
} from './components'
import React from 'react'

function App() {
  return (
    <React.Fragment>
      <NavBar />
      <BannerIntro />
      <Dot />
      <Products />
      <About />
      <Services />
      <Service />
      <ContactUs />
      <Footer />
    </React.Fragment>
  );
}

export default App;
