import React from 'react'
import breathe from "../images/breath.png"
import fast from "../images/fast.png"
import cube from "../images/cube.png"
import pay from "../images/pay.png"
import stripe from "../images/stripe.png"

const Services = () => {
    return (
        <section className="section2" id="services">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <p className="section2-head"><span className="span-head">Why work </span> with skysand <br/> technologies?</p>
                    <p className="section2-head-sub">Here are the reasons why we think we are the best option for you.</p>
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="card shadow">
                                <div className="row">
                                    <div className="col-md-1 col-1">
                                        <img src={breathe} alt="" />
                                    </div>
                                    <div className="col-md-8 col-8">
                                       <p className="easy pt-1">Breathe easy</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card shadow">
                                <div className="row">
                                    <div className="col-md-1 col-1">
                                        <img src={fast} alt="" />
                                    </div>
                                    <div className="col-md-8 col-8">
                                       <p className="easy pt-2">Fast and efficient</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="card shadow">
                                <div className="row">
                                    <div className="col-md-1 col-1">
                                        <img src={cube} alt="" />
                                    </div>
                                    <div className="col-md-8 col-8">
                                       <p className="easy pt-2">Build smarter</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card shadow">
                                <div className="row">
                                    <div className="col-md-1 col-1">
                                        <img src={pay} alt="" />
                                    </div>
                                    <div className="col-md-8 col-8">
                                       <p className="easy pt-2 mt-1">Pay as you go</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <img src={stripe} alt="" width="100%" height="100%" className="d-none d-md-block" />
                </div>
            </div>
        </div>
    </section>
    )
}

export default Services