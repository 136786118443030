import React from 'react'
import robo from "../images/robo.png"

const ContactUs = () => {
    return (
        <section className="section4" id="talk">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-12">
                    <h1 className="contact">Contact Us</h1>
                    <p className="section2-head-sub text-white pt-2">Send us a message, let us know how we can assist you and  your <br/> company.</p>

                   <form action="">
                        <label for="" className="mt-3 text-white"><h5>Email Address</h5> </label>
                        <input type="email" className="form-control" placeholder="Your Email" required />

                        <label for="" className="mt-4 text-white"><h5>Message</h5></label>
                        <textarea name="" id="" cols="30" rows="10" placeholder="Your Message" className="form-control" required></textarea>

                        <button type="submit" className="btn btn-send"> Send message</button>
                   </form>
                </div>
                <div className="col-md-6 col-12">
                    <img src={robo} alt="" width="659px" height="600px" className="robo" />
                </div>
            </div>
        </div>
    </section>
    )
}

export default ContactUs