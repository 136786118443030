import React from 'react'
import list from "../images/list.png"
import globe from "../images/globe.png"


const About = () => {
    return (
        <section className="section1" id="about">
        <div className="container">
            <p className="text-center setionhead">What is skysand all about?</p>
            <p className="text-center setionhead-sub d-none d-md-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod <br/> tempor incididunt ut labore et dolore </p>
            <p className="text-center setionhead-sub d-block d-md-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore </p>
            <div className="row mt-5 pt-5">
                <div className="col-md-6">
                    <div>
                        <div className="row justify-content-center">
                        <div className="col-md-1 col-1">
                            <img src={list} alt="" width="80%" className="list" />
                        </div>
                        <div className="col-md-7 col-10 text-white">
                            <h3>Problem Solving</h3>
                        </div>
                    </div>
                    <p className="text-white lorem">Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor incididunt  ut labore et dolore magna aliqua. Ut enim ad  minim veniam, quis nostrud exercitation ullamco  laboris nisi ut aliquip ex ea commodo consequat.  Duis aute irure dolor in reprehenderit in voluptate  velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>

                <div className="pt-5">
                    <div className="row justify-content-center">
                    <div className="col-md-1 col-1">
                        <img src={list} alt="" width="80%" className="list" />
                    </div>
                    <div className="col-md-7 col-10 text-white">
                        <h3>Research and market analysis</h3>
                    </div>
                </div>
                <p className="text-white lorem">Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor incididunt  ut labore et dolore magna aliqua. Ut enim ad  minim veniam, quis nostrud exercitation ullamco  laboris nisi ut aliquip ex ea commodo consequat.  Duis aute irure dolor in reprehenderit in voluptate  velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <div className="row justify-content-center">
                        <div className="col-md-1 col-1">
                            <img src={list} alt="" width="80%" className="list" />
                        </div>
                        <div className="col-md-7 col-10 text-white">
                            <h3>Software development</h3>
                        </div>
                    </div>
                    <p className="text-white lorem">Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor incididunt  ut labore et dolore magna aliqua. Ut enim ad  minim veniam, quis nostrud exercitation ullamco  laboris nisi ut aliquip ex ea commodo consequat.  Duis aute irure dolor in reprehenderit in voluptate  velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div>

                <div className="pt-5">
                    <div className="row justify-content-center">
                    <div className="col-md-1 col-1">
                        <img src={list} alt="" width="80%" className="list" />
                    </div>
                    <div className="col-md-7 col-10 text-white">
                        <h3>Web development</h3>
                    </div>
                </div>
                <p className="text-white lorem pb-5">Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed do eiusmod tempor incididunt  ut labore et dolore magna aliqua. Ut enim ad  minim veniam, quis nostrud exercitation ullamco  laboris nisi ut aliquip ex ea commodo consequat.  Duis aute irure dolor in reprehenderit in voluptate  velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
                </div>
            </div>
            </div>
            <div classNameName="glob">
                <img src={globe} alt="" className="globe d-none d-md-block" />
            </div>
    </section>
    )
}

export default About