import React from 'react'
import full from "../images/fullstack.png"
import prod from "../images/product.png"
import design from "../images/designer.png"

const Products = () => {
    return (
        <section className="section" id="products">
        <div className="container">
            <p className="text-center talent"><span className="span-head">Talent is the difference </span>between <br/> good and great products</p>
            <p className="text-center talent-sub pt-3">Get elite software developers on demand—plus every resource your <br/> squad needs to make your product successful.</p>
            <div className="row mt-md-5">
                <div className="col-md-4">
                    <img src={full} alt="" width="100%" className="fullstack" />
                </div>
                <div className="col-md-4">
                    <img src={prod} alt="" width="100%" className="fullstack" />
                </div>
                <div className="col-md-4">
                    <img src={design} alt="" width="100%" className="fullstack" />
                </div>

            </div>
        </div>
    </section>
    )
}

export default Products