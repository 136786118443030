import React from 'react'
import building from "../images/building.png"

const BannerIntro = () => {
    return (
        <section className="sect">
        <div className="container py-5">
            <div className="row">
                <div className="col-md-7 col-10">
                <p className="text-head"> <span className="span-head">We create software <br /> development </span> solutions <br /> for your business</p>
                <p className="sub-head">The smartest possible way to acquire a high-performing distributed team</p>

                <a href="#talk"><button className="btn btn-let"> Let's talk </button></a> 
             </div>
             <div className="col-md-5">
                <img src={building} alt="" className="buildimage d-none d-md-block" />
             </div>
         </div>    
             </div> 
             <a href="#top">
                <i className="fa fa-arrow-up arrow"></i>
                </a>
            </section> 
           
    )
}

export default BannerIntro