import React from 'react'
import LOGO from "../images/SKY SAND TECHNOLOGIES BRAND MARKS 1.png"

const NavBar = () => {
    return (
        <nav role="navigation" className="navbar navbar-expand-md" id="top">
            <div className="container">
                <a href="index.html" className="navbar-brand">
                    <img src={LOGO} alt="" width="100%" />
                </a>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#toggle">
                    <span><i className="fa fa-bars icon"></i></span>
                    {/* <!-- <span className="navbar-toggler-icon icon"></span>  --> */}
                </button>
                <div className="navbar-collapse collapse" id="toggle" >
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-items"> <a href="#products" className="nav-link">Products</a></li>
                        <li className="nav-items"><a href="#services" className="nav-link">Services</a></li>
                        <li className="nav-items"> <a href="#about" className="nav-link">About Us</a></li>
                        <li className="nav-items"> <a href="#blog" className="nav-link">Blog</a></li>
                    </ul>
                    <a href="#talk"><button className="btn btn-talk"> Let's talk </button></a>
                </div>
            </div>
        </nav>
    )
}

export default NavBar