import React from 'react'
import logo from "../images/SKY SAND TECHNOLOGIES BRAND MARKS 1.png";

const Footer = () => {
    return (
        <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-0 text-white">
                <img src={logo} alt="" />
                    <p className="pt-4 foots pl-5">©2022</p>
                       <p className="foots pl-5">All Rights Reserved.</p>
                </div>
                <div className="col-md-2 col-4 pl-5 ml-5">
                    <h4 className="foot">About</h4>
                    <a href="#about"><p className="foots">About Us</p></a>
                    <a href=""><p className="foots">FAQs</p></a>
                    <a href="#talk"><p className="foots">Contact Us</p></a>
                </div>
                <div className="col-md-2 col-4 pl-5 ml-5">
                    <h4 className="foot">Contact Us</h4>
                    <a href="#talk"><p className="foots">Chat with us</p></a>
                    <a href="#talk"><p className="foots">Send an email</p></a>
                    <a href="#talk"><p className="foots">Support</p></a>
                </div>
                <div className="col-md-2 col-4 pl-5 ml-5">
                    <h4 className="foot">Social Media</h4>
                    <p className="foots">Facebook</p>
                    <p className="foots">Instagram</p>
                    <p className="foots">Twitter</p>
                    <p className="foots">Medium</p>
                </div>
                <div className="col-md-2 col-4 pl-5 ml-5">
                    <h4 className="foot">Legal</h4>
                    <p className="foots">Terms of Use</p>
                    <p className="foots">Privacy Policy</p>
                </div>
                <div className="col-md-2">
                </div>
            </div>
            <div>
               
            </div>

        </div>
    </footer>
    )
}

export default Footer