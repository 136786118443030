import React from 'react'
import robot from "../images/robot.png"
import code from "../images/code.png"

const Service = () => {
  return (
    <section className="section3" id="blog">
        <div className="container">
            <p className="section3-head d-none d-md-block">Work with the customer centric <br/> team that delivers</p>
            <p className="section3-head d-block d-md-none">Work with the customer centric team that delivers</p>
            <p className="section2-head-sub">Invaluable Agile Team Management Experienence. Simplified <br/> Monthly Cost.</p>
            <div className="row mt-5">
                <div className="col-md-6 col-12">
                    <img src={robot} alt="" className="robot" />
                </div>
                <div className="col-md-6 col-0">
                    <img src={code} alt="" className="code" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Service